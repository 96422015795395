import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import EvaluationCategoriesEnum from "../enum/EvaluationCategoriesEnum";
import AreasEnum from '../enum/AreasEnum';
import { renderEmojiSet } from "../enum/EmojiUtils";
import '../pages/Css/SendEvaluation.css';

const RatingComedor = () => {
    const { areaId } = useParams();

    const [ratings, setRatings] = useState({
        servicio: 0,
        comida: 0,
        limpieza: 0,
    });

    const [feedback, setFeedback] = useState(""); // Estado para el comentario opcional
    const [isSubmitting, setIsSubmitting] = useState(false); // Estado para evitar envíos múltiples

    const getEvaluationTypeId = (category) => {
        const types = {
            servicio: 1,
            comida: 2,
            limpieza: 3,
        };
        return types[category];
    };

    const categories = [
        EvaluationCategoriesEnum.SERVICIO,
        EvaluationCategoriesEnum.COMIDA,
        EvaluationCategoriesEnum.LIMPIEZA,
    ];

    const allRatingsSelected = Object.values(ratings).every((rating) => rating > 0);

    const handleEmojiClick = (category, selectedRating) => {
        setRatings((prevRatings) => ({
            ...prevRatings,
            [category]: prevRatings[category] === selectedRating ? 0 : selectedRating,
        }));
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmit = () => {
        if (!allRatingsSelected) {
            Swal.fire({
                icon: "warning",
                title: "Atención",
                text: "Debes seleccionar una calificación en cada categoría antes de enviar.",
            });
            return;
        }

        setIsSubmitting(true); // Evitar envíos múltiples

        const data = Object.keys(ratings).map((category) => ({
            nameuser: AreasEnum.USR,
            idArea: areaId,
            idEvaluationType: getEvaluationTypeId(category),
            idRating: ratings[category],
            feedback: feedback.trim() !== "" ? feedback : "Sin comentarios",
        }));

        axios.post(`${process.env.REACT_APP_URL_BASE}/areas-evaluations/send-evaluations-cmd`, data)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: "Éxito",
                    text: "Evaluaciones enviadas correctamente.",
                });
                setRatings({
                    servicio: 0,
                    comida: 0,
                    limpieza: 0,
                });
                setFeedback("");
            })
            .catch(() => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un error al enviar las evaluaciones.",
                });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className="ratings-comedor">
            {categories.map((category) => (
                <div key={category}>
                    {renderEmojiSet(category, ratings, handleEmojiClick)}
                </div>
            ))}

            <div className="feedback-container">
                <label htmlFor="feedback">Comentario (opcional):</label>
                <textarea
                    id="feedback"
                    className="feedback"
                    value={feedback}
                    onChange={handleFeedbackChange}
                    placeholder="¿Qué platillo comiste hoy?  ¿tienes alguna duda o sugerencia?"
                />
            </div>

            <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className={`submit-button ${!allRatingsSelected ? "disabled" : ""}`}
            >
                {isSubmitting ? "Enviando..." : "Enviar Evaluación"}
            </button>
        </div>
    );
};

export default RatingComedor;