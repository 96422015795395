import React, { useEffect, useState } from "react";
import axios from "axios";
import './Css/DataEvaluation.css';
import { FcApproval } from "react-icons/fc";
import Swal from 'sweetalert2';

function DataEvaluations({ searchTerm, onDataLoaded }) {
    const [evaluations, setEvaluations] = useState([]);
    const [sortedEvaluations, setSortedEvaluations] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("authToken");
                const response = await axios.post(
                    `${process.env.REACT_APP_URL_BASE}/areas-evaluations/get-evaluations`,
                    {},
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.data.status) {
                    setEvaluations(response.data.data);
                    setSortedEvaluations(response.data.data);
                    if (onDataLoaded) {
                        onDataLoaded(response.data.data);
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Ocurrió un problema en la respuesta del servidor. Por favor, inténtalo más tarde.",
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ocurrió un problema al obtener los datos. Verifica tu conexión o inténtalo más tarde.",
                });
            }
        };

        fetchData();
    }, []);

    const filteredEvaluations = sortedEvaluations
        ? sortedEvaluations.filter(
            (item) =>
                (item.area && item.area.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.atributo && item.atributo.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.descripcion && item.descripcion.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : [];

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        } else if (sortConfig.key === key && sortConfig.direction === "desc") {
            direction = null;
        }

        setSortConfig({ key, direction });

        if (direction) {
            const sorted = [...filteredEvaluations].sort((a, b) => {
                if (key === "fecha") {
                    return direction === "asc"
                        ? new Date(a[key]) - new Date(b[key])
                        : new Date(b[key]) - new Date(a[key]);
                } else if (key === "calificacion") {
                    return direction === "asc" ? a[key] - b[key] : b[key] - a[key];
                } else {
                    return a[key].localeCompare(b[key]) * (direction === "asc" ? 1 : -1);
                }
            });
            setSortedEvaluations(sorted);
        } else {
            setSortedEvaluations(evaluations);
        }
    };

    return (
        <div className="hscroll">
            {filteredEvaluations.length > 0 ? (
                <table className="evaluation-table">
                    <thead>
                    <tr>
                        <th onClick={() => handleSort("idEvaluation")}>Id</th>
                        <th onClick={() => handleSort("area")}>Área</th>
                        <th onClick={() => handleSort("atributo")}>Propiedad</th>
                        <th onClick={() => handleSort("calificacion")}>
                            <FcApproval />
                        </th>
                        <th onClick={() => handleSort("descripcion")}>Tipo</th>
                        <th onClick={() => handleSort("fecha")}>Fecha</th>
                        <th onClick={() => handleSort("feedback")}>Comentarios</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredEvaluations.map((item) => (
                        <tr key={item.idEvaluation}>
                            <td>{item.idEvaluation}</td>
                            <td>{item.area}</td>
                            <td>{item.atributo}</td>
                            <td>{item.calificacion}</td>
                            <td>{item.descripcion}</td>
                            <td>{item.fecha}</td>
                            <td>{item.feedback}</td> {/* Aquí se muestran todos los comentarios sin filtro */}
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <p>No hay datos disponibles</p>
            )}
        </div>
    );
}

export default DataEvaluations;
